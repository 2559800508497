
import Vue, { VueConstructor } from "vue";
import KPaginatedAutocomplete from "@/components/crud/fields/KPaginatedAutocomplete.vue";
import { sourceAutocomplete } from "@/modules/salesOrder/api/source";

export default (
  Vue as VueConstructor<
    Vue & {
      $refs: {
        autocomplete: {
          refresh: () => Promise<unknown>;
        };
      };
    }
  >
).extend({
  name: "SourceAutocomplete",
  components: { KPaginatedAutocomplete },
  props: {
    clientId: {
      type: Number,
      required: true,
    },
  },
  watch: {
    clientId: {
      handler() {
        this.$refs.autocomplete.refresh();
      },
    },
  },
  methods: {
    async sourceAutocomplete(data: any) {
      const b = await sourceAutocomplete(this.clientId, data);
      b.data.data = b.data.data.map((source) => ({
        ...source,
        title:
          source.originalSource === "DET"
            ? this.$t(`salesOrder.sources.${source.originalSource}`)
            : source.originalSource,
      }));
      return b;
    },
  },
});
