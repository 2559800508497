
import Vue, { PropType } from "vue";
import KFieldGroup from "@/components/crud/fields/KFieldGroup.vue";
import KTextField from "@/components/crud/fields/KTextField.vue";
import dayjs from "@/plugins/dayjs";
import SourceAutocomplete from "@/modules/salesOrder/components/SourceAutocomplete.vue";
import SalesOrderStatusAutocomplete from "@/modules/salesOrder/components/SalesOrderStatusAutocomplete.vue";
import { mapGetters } from "vuex";

type ComponentData = {
  internalFilters: Record<string, any>;
};

export default Vue.extend({
  name: "SalesOrderFilter",
  components: {
    SalesOrderStatusAutocomplete,
    SourceAutocomplete,
    KTextField,
    KFieldGroup,
  },
  props: {
    filters: {
      type: Object as PropType<Record<string, any>>,
      required: true,
    },
  },
  data: (): ComponentData => ({
    internalFilters: {
      orderDateFrom: undefined,
      orderDateTo: undefined,
      source: undefined,
      salesOrderStatusId: undefined,
    },
  }),
  computed: {
    ...mapGetters("authorisation", ["client"]),
    orderDateFrom: {
      get: function () {
        if (!this.internalFilters?.orderDateFrom) return;
        return dayjs(this.internalFilters?.orderDateFrom).format("YYYY-MM-DD");
      },
      set: function (newValue?: string) {
        this.internalFilters.orderDateFrom = newValue
          ? dayjs(newValue).startOf("date").format("YYYY-MM-DD HH:mm:ss")
          : newValue;
      },
    },
    orderDateTo: {
      get: function () {
        if (!this.internalFilters?.orderDateTo) return;
        return dayjs(this.internalFilters?.orderDateTo).format("YYYY-MM-DD");
      },
      set: function (newValue?: string) {
        this.internalFilters.orderDateTo = newValue
          ? dayjs(newValue).endOf("date").format("YYYY-MM-DD HH:mm:ss")
          : newValue;
      },
    },
  },
  watch: {
    filters: {
      handler(newFilters) {
        this.internalFilters = newFilters;
      },
      immediate: true,
      deep: true,
    },
    internalFilters: {
      handler(newFilters) {
        this.$emit("update:filters", newFilters);
      },
      deep: true,
    },
  },
});
