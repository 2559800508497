import i18n from "@/plugins/i18n";

export type TranslatedEnumOptions = { text: string; value: string | number }[];

/**
 * get the key of an enum by the enumObject and the id of a resource's enumId (or typeId)
 */
export const getEnumKeyByValue = (
  enumObject: Record<string, string | number>,
  enumValue: string | number
): string | undefined => {
  return Object.keys(enumObject).find(
    (key) => enumObject[key].toString() === enumValue.toString()
  );
};

/**
 * get the translated value of an enum and a value
 */
export const getTranslatedEnumValue = (
  enumObject: Record<string, string | number>,
  enumValue: string | number,
  translationPrefix: string
): string => {
  return i18n.t(
    `${translationPrefix}.${getEnumKeyByValue(enumObject, enumValue)}`
  ) as string;
};

/**
 * get a array with an enum's options with it's translated key and it's value
 */
export const getTranslatedEnumOptions = (
  enumObject: Record<string, string | number>,
  translationPrefix: string
): TranslatedEnumOptions => {
  return Object.keys(enumObject).map((key) => ({
    text: i18n.t(`${translationPrefix}.${key}`) as string,
    value: enumObject[key],
  }));
};
