import {
  getPaginated,
  PaginatedRequest,
  PaginatedResponse,
} from "@/application/api/getPaginated";
import { AxiosResponse } from "axios";

export const salesOrderStatusAutocomplete = (
  clientId: number,
  data: PaginatedRequest
): Promise<AxiosResponse<PaginatedResponse<{ id: number; name: string }[]>>> =>
  getPaginated(`client/${clientId}/sales-order-status/autocomplete`, data);
