import {
  getPaginated,
  PaginatedRequest,
  PaginatedResponse,
} from "@/application/api/getPaginated";
import { AxiosResponse } from "axios";
import mapIndexResponseErrors from "@/application/util/mapIndexResponseErrors";

export interface SalesOrderIndexItem {
  id: number;
  orderDate?: string;
  orderNumber?: string;
  name: {
    companyName?: string;
    customerName?: string;
  };
  city?: string;
  channel?: string;
  salesOrderStatus?: string;
  tracking?: string;
}

export const clientSalesOrderIndex = async (
  data: PaginatedRequest,
  clientId: number
): Promise<AxiosResponse<PaginatedResponse<SalesOrderIndexItem[]>>> => {
  const response = await getPaginated(`client/${clientId}/sales-order`, data);
  response.data.data = mapIndexResponseErrors(response.data.data);

  return response;
};
