
import Vue from "vue";
import { OrderStatus } from "@/modules/salesOrder/enum/OrderStatus";
import { getTranslatedEnumValue } from "@/application/util/enum";
import { orderStatusColor } from "@/modules/salesOrder/util/OrderStatusColor";

export default Vue.extend({
  name: "OrderStatusChip",
  props: {
    value: {
      type: Number,
      required: true,
    },
  },
  computed: {
    color() {
      return orderStatusColor[this.value as keyof OrderStatus];
    },
    text(): string {
      return getTranslatedEnumValue(
        OrderStatus,
        this.value as keyof OrderStatus,
        "salesOrder.orderStatus"
      );
    },
  },
});
