import {
  getPaginated,
  PaginatedRequest,
  PaginatedResponse,
} from "@/application/api/getPaginated";
import { AxiosResponse } from "axios";

export const sourceAutocomplete = (
  clientId: number,
  data: PaginatedRequest
): Promise<AxiosResponse<PaginatedResponse<{ originalSource: string }[]>>> =>
  getPaginated(`client/${clientId}/source/autocomplete`, data);
